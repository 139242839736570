var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container col-xl-10 col-xxl-8 p-0 p-md-5 my-sm-5" },
    [
      _vm.loading && !(_vm.form.id && !_vm.form.upload_contract)
        ? _c(
            "div",
            {
              staticClass:
                "p-5 d-flex justify-content-center align-items-center flex-gap-2",
            },
            [
              _c("div", {
                staticClass: "spinner-border text-primary",
                attrs: { role: "status" },
              }),
              _c("span", [_vm._v("Please wait...")]),
            ]
          )
        : _vm._e(),
      _vm.form.id && !_vm.form.upload_contract
        ? [
            _c(
              "div",
              {
                staticClass:
                  "row align-items-center p-5 bg-white border shadow-lg rounded",
              },
              [
                _c("div", { staticClass: "col-lg-7" }, [
                  _c("h1", { staticClass: "display-4 mb-3" }, [
                    _vm._v(_vm._s(_vm.form.restaurant_name)),
                  ]),
                  _c("p", { staticClass: "col-lg-10" }),
                  _c("address", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.form.restaurant_address) +
                        " " +
                        _vm._s(_vm.form.restaurant_postcode) +
                        ", " +
                        _vm._s(_vm.form.restaurant_city) +
                        " "
                    ),
                  ]),
                  _c("p"),
                ]),
                _c("div", { staticClass: "col-md-10 mx-auto col-lg-5" }, [
                  _c(
                    "form",
                    {
                      staticClass: "p-4 p-md-5 border rounded bg-light",
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.generateContract()
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "form-group mb-3" }, [
                        _c("label", [_vm._v("Name")]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.formContract.name,
                              expression: "formContract.name",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            placeholder: "Name",
                            required: "",
                          },
                          domProps: { value: _vm.formContract.name },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.formContract,
                                "name",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                      _c("div", { staticClass: "form-group mb-3" }, [
                        _c("label", [_vm._v("Place")]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.formContract.place,
                              expression: "formContract.place",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            placeholder: "Place",
                            required: "",
                          },
                          domProps: { value: _vm.formContract.place },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.formContract,
                                "place",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                      _c(
                        "div",
                        { staticClass: "d-flex flex-column flex-md-row mb-3" },
                        [
                          _c(
                            "div",
                            { staticClass: "form-group w-100" },
                            [
                              _c("label", { staticClass: "d-block" }, [
                                _vm._v("Signature:"),
                              ]),
                              _c("VueSignaturePad", {
                                ref: "signaturePad",
                                staticClass: "form-control",
                                attrs: {
                                  id: "signature",
                                  width: "100%",
                                  height: "160px",
                                  options: {
                                    penColor: "#000",
                                    onBegin: () => {
                                      _vm.$refs.signaturePad.resizeCanvas()
                                    },
                                  },
                                },
                              }),
                              _c(
                                "a",
                                {
                                  staticClass: "small",
                                  attrs: { role: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$refs.signaturePad.undoSignature()
                                    },
                                  },
                                },
                                [
                                  _c("CIcon", {
                                    attrs: { name: "cil-action-undo" },
                                  }),
                                  _vm._v(" Undo the Signature "),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "button",
                        { staticClass: "w-100 btn btn-lg btn-primary" },
                        [_vm._v("Sign the Contract")]
                      ),
                      _c("hr", { staticClass: "my-4" }),
                      _c("small", { staticClass: "text-muted" }, [
                        _vm._v(
                          "By clicking Sign the Contract, you agree to the terms of use."
                        ),
                      ]),
                    ]
                  ),
                ]),
              ]
            ),
            _c("RestaurantContract", {
              ref: "contract",
              attrs: {
                form: _vm.form,
                formContract: _vm.formContract,
                sign: _vm.formContract.sign,
                saveFile: false,
              },
              on: { generated: _vm.handleContractGenerationComplete },
            }),
            _c("CElementCover", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              attrs: { opacity: 0.8 },
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }